import logo from './logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p><h1>VIVA LA ROK</h1><h5>Coming soon</h5></p>
        <p>
          <a href="https://www.instagram.com/vivalarok/" style={{ textDecoration: 'none' }}><h2 style={{ textDecoration: 'none' }}>IG:@vivalarok</h2></a>
        </p>
      </header>
    </div>
  );
}

export default App;
